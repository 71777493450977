import { React } from "react";
import { withTranslation } from "react-i18next";
import style from "./scss/productScreen.module.scss";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Icon,
  Grid,
  GridColumn,
  Form,
  FormGroup,
  TextArea,
} from "semantic-ui-react";
import regChild from "../../assets/images/RegChild.webp";
import closeIcon from "../../assets/images/closeIcon.webp";
import { resturls } from "globals/utils/apiurls";
import GlobalService from "services/GlobalService";

const RegistrationForm = ({ handleRegModal }) => {
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  const formSubmit = (formData) => {
    const formContent = new FormData();
    const eventName = "Image of a child Demo";
    formContent.append("name", formData.name);
    formContent.append("email", formData.email);
    formContent.append("mobilenumber", formData.phone);
    formContent.append("location", formData.location);
    formContent.append("userType", formData.message);
    formContent.append("institution", formData.organization);
    formContent.append("registrationPurpose", eventName);
    formContent.append("type", "SchoolAge");
    GlobalService.generalSelect(
      (respdata) => {
        if (respdata) {
          //
        }
      },
      `${resturls.registerWebsiteUser}`,
      formContent,
      "POST",
      "app"
    );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("PhoneNumber is required"),
    organization: Yup.string().required("Organization is required"),
    location: Yup.string().required("Location is required"),
  });

  return (
    <>
      <div className={style.formWrapper}>
        <div className={style.formHeaderWrapper}>
          <h2 className={style.heading}>
            Become an SchoolAge EdTech Franchisee
          </h2>
          <div
            className={style.closeIcon}
            onClick={() => handleRegModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
        <Grid>
          <GridColumn width={isResponsive ? 16 : 10}>
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                organization: "",
                location: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                formSubmit(values);
                resetForm();
              }}
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                handleBlur,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Input
                      fluid
                      name="name"
                      label="Name"
                      type="text"
                      value={values.name}
                      className={style.formField}
                      onChange={handleChange}
                      error={touched.name && errors.name}
                      onBlur={handleBlur}
                    />
                    <Form.Input
                      fluid
                      name="email"
                      type="text"
                      label="Email*"
                      value={values.email}
                      className={style.formField}
                      onChange={handleChange}
                      error={touched.email && errors.email}
                      onBlur={handleBlur}
                    />
                    <Form.Input
                      fluid
                      name="phone"
                      type="number"
                      label="Phone*"
                      value={values.phone}
                      className={style.formField}
                      onChange={handleChange}
                      error={touched.phone && errors.phone}
                      onBlur={handleBlur}
                    />
                    <FormGroup widths="equal">
                      <Form.Input
                        fluid
                        name="organization"
                        type="text"
                        label="Organisation name"
                        value={values.organization}
                        className={style.formField}
                        onChange={handleChange}
                        error={touched.organization && errors.organization}
                        onBlur={handleBlur}
                      />
                      <Form.Input
                        fluid
                        name="location"
                        type="text"
                        label="Location"
                        value={values.location}
                        className={style.formField}
                        onChange={handleChange}
                        error={touched.location && errors.location}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                    <Form.Field
                      fluid
                      name="message"
                      label="Message*"
                      value={values.message}
                      className={style.formField}
                      onChange={handleChange}
                      control={TextArea}
                    />
                    <Button
                      size="big"
                      type="submit"
                      className={style.submitBtn}
                      color="orange"
                    >
                      Submit <Icon name="arrow right"></Icon>
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </GridColumn>
          {!isResponsive && (
            <GridColumn width={6} className={style.imageGrid}>
              <img src={regChild} />
            </GridColumn>
          )}
        </Grid>
      </div>
    </>
  );
};
export default withTranslation("product")(RegistrationForm);
