/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef } from "react";
import style from "./scss/privacyPolicy.module.scss";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  const location = useLocation();
  const termContainer = useRef(null);
  const deleteRequest = useRef(null);
  useEffect(() => {
    const lastPathSegment = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );

    const path1 = "privacyPolicy";
    const path2 = "termsAndConditions";
    const path3 = "deleteRequest";

    if (lastPathSegment === path1) {
      window.scrollTo(0, 0);
    } else if (lastPathSegment === path2 && termContainer.current) {
      termContainer.current.scrollIntoView({ behavior: "smooth" });
    } else if (lastPathSegment === path3 && deleteRequest.current) {
      deleteRequest.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.pathname]);

  return (
    <div className={style.container}>
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date: June 26, 2024</strong>
      </p>
      <p>
        Imageofachild ("we", "our", "us") is committed to protecting the privacy
        of our users, especially children under the age of 13. This Privacy
        Policy explains how we collect, use, and safeguard your and your child's
        personal information. By using the Imageofachild platform, you agree to
        the practices described in this policy.
      </p>

      <p>
        The Website is provided for information about our App. The Website is
        intended for adults only and does not allow game play.
      </p>
      <p>
        The App downloaded to your device provides a learn and play experience
        (the “Imageofachild Early Learning App”) and is intended for families
        and users of all ages.
      </p>

      <p>
        Please read this policy in its entirety, including our Children’s
        Privacy Policy section, before using our Services. The Children’s
        Privacy Policy will control over any conflicting provisions in our
        general Privacy Policy as it concerns children’s data.
      </p>

      <h2>Children’s Privacy Policy</h2>
      <p>Protecting the privacy of children is especially important.</p>

      <p>
        <strong>Website:</strong> The Website is not intended for children, not
        market to children, and does not knowingly collect personal information
        from children. All references to “children” or “child” in this
        Children’s Privacy Policy shall mean children under 13 years of age, and
        all references to the term “parent” shall include legal guardians. If
        you are under 13 years of age, then please do not use or access the
        Website at any time or in any manner. If we learn that personally
        identifiable information has been provided to us and/or collected on the
        Website from persons under 13 years of age and without verifiable
        parental consent, such as through a support request, we take the
        appropriate steps to delete this information. If you are a parent and
        discover that your child under 13 years of age has provided personal
        information through the Website, then you may alert us at
        contact@imageofachild.ai and request that we delete that child’s
        information from our systems. A parent or teacher can visit his or her
        account at any time to delete any information and/or the account.
      </p>

      <p>
        <strong>App:</strong> We encourage parents and teachers to supervise
        their child or student’s use of the App. No personal information is
        collected or solicited from a child user of the App. We may collect
        analytics data (which does not contain personal information) from a
        child’s use of the App in order to offer and develop the Service, and we
        may collect information about a child such as name and age from you as
        part of your use of the App and the creation of your child’s user
        profile. We strive to keep the level of information collected from your
        child to a minimum, and in no event do we collect more information than
        what is required to use our learning app. The App contains no external
        links to third-party websites or services.
      </p>

      <p>
        To enable interactive features as part of the learning journey, the App
        may request permission to access your device’s camera and microphone. We
        capture images, video, and audio to enhance the learning experience for
        both the child and the parent/guardian/teacher. The images, videos, and
        audio are processed to provide a better learning experience and will not
        be shared with any third parties. The data collected will only be
        viewable through the account used by the parent for the child's learning
        purposes.
      </p>

      <p>
        We will obtain prior parental consent if any changes we make to this
        Privacy Policy affect users under the age of 13 in a way that requires
        such consent under Google Play Family Policies.
      </p>

      <h2>Data Collected From Our Services</h2>

      <h3>Personal Information We Collect</h3>
      <p>
        We collect personal information from parents or guardians during account
        registration, including the parent or guardian’s name, email address,
        child’s name, and child’s date of birth. Additionally, we gather
        information about how the platform is used, which includes device
        information, IP address, browser type, operating system, and activity
        logs.
      </p>

      <h3>How We Use Information</h3>
      <p>
        We use the collected information to provide, maintain, and improve our
        services, personalize the user experience, communicate with parents or
        guardians about their account and our services, ensure compliance with
        our terms and conditions, and protect against unauthorized access and
        misuse of our platform.
      </p>

      <h3>Information Sharing and Disclosure</h3>
      <p>
        We do not sell or rent personal information to third parties. We may
        share information with third parties only in the following
        circumstances: with service providers who perform services on our
        behalf, to comply with legal obligations or respond to lawful requests
        from public authorities, and to protect the rights, property, or safety
        of Imageofachild, our users, or others.
      </p>

      <h3>Cookies</h3>
      <p>
        We use cookies and similar technologies to enhance your experience on
        our platform. Cookies are small text files that are stored on your
        device when you visit a website. They help us remember your preferences
        and settings, improve the performance of our website, and personalize
        your experience. You can control cookie settings through your browser
        settings. This allows you to accept or reject cookies, and to delete
        cookies that have already been stored on your device. However, please
        note that disabling cookies may affect the functionality of our platform
        and your ability to use certain features effectively. By continuing to
        use our platform, you consent to our use of cookies as described in our
        Cookie Policy.
      </p>

      <h3>Data Security</h3>
      <p>
        We implement appropriate security measures to protect personal
        information from unauthorized access, alteration, disclosure, or
        destruction. However, no internet or email transmission is ever fully
        secure or error-free.
      </p>

      <h3>Data Processing</h3>
      <p>
        The audio, video, and image submissions collected from you on behalf of
        the child will be analyzed using AI methodologies to provide immediate
        feedback, enhancing your experience. We do not share this information
        with any third parties. Data processing is conducted using our
        proprietary resources and external accounts managed by us to optimize
        this capability.
      </p>

      <h3>Parental Rights and Controls</h3>
      <p>
        Parents or guardians have the right to review and update their child's
        personal information, request the deletion of their child's personal
        information, and withdraw consent for the collection and use of their
        child's information. To exercise these rights, please refer to the
        Individual Rights section.
      </p>

      <h2>Individual Rights</h2>

      <h3>Changes to this Privacy Policy</h3>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any significant changes by posting the new policy on our platform and
        updating the effective date. Continued use of the platform after changes
        are made constitutes acceptance of the new policy.
      </p>

      <h3>Acceptable Use</h3>
      <p>
        The app is intended solely for educational purposes. Users are expected
        to use the app in accordance with these educational objectives. It is
        important to respect the rights of others, including intellectual
        property rights, privacy, and dignity. Users must also comply with all
        applicable laws and regulations while using the app. This includes
        refraining from any activities that may be illegal, harmful, or
        disruptive to others' use of the app. By using the app, users agree to
        uphold these standards of acceptable use and conduct.
      </p>

      <h3>Prohibited Use</h3>
      <p>
        Prohibited activities within the app include harassment, which
        encompasses any form of abusive, offensive, or intimidating behavior
        towards others. Users must refrain from uploading or sharing illegal
        content, including but not limited to material that violates
        intellectual property rights, promotes violence, or contains explicit
        material inappropriate for educational settings. Additionally, any
        attempts to breach the app's security protocols or access unauthorized
        features are strictly prohibited.
      </p>

      <h3>Consequences of Violation</h3>
      <p>
        Violations of these guidelines may lead to account suspension or
        termination. Depending on the severity of the violation, disciplinary
        actions will be taken to maintain a safe and respectful environment for
        all users.
      </p>

      <h3 ref={deleteRequest}>Reporting Violations</h3>
      <p>
        To report any instances of abuse or violations of this policy, please
        contact us at contact@imageofachild.ai. Your assistance in maintaining a
        positive and secure community is greatly appreciated.
      </p>

      <h3>Delete Your Data</h3>
      <p>
        You also have the right to have your personal data deleted. This is
        sometimes known as the ‘right to be forgotten’. To request that we
        delete all personal data about you, please submit a request using the
        below information. We may request more information to confirm your
        identity before deleting any personal data. You must submit a request
        using the below information if you wish to delete all personal data from
        our system. After you delete your personal data from our services, we
        may not immediately delete residual copies from our active servers and
        may not immediately remove data from our backup systems, for archiving,
        prevention of fraud and abuse, analytics, satisfaction of legal
        obligations, or where we otherwise reasonably believe that we have a
        legitimate reason to do so. We may also decline to honor this request in
        certain specific situations, such as if the data is necessary to comply
        with a legal obligation or to exercise/defend a legal claim.
      </p>

      <h3>Contact for Individual Rights Requests</h3>
      <p>
        Please use the below information when submitting a request to exercise
        any of the above rights. Please do not submit requests across multiple
        communications channels. We will make all efforts to respond to your
        request within a reasonable timeframe.
      </p>

      <h3 ref={termContainer}>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at:
      </p>
      <p>Email: contact@imageofachild.ai</p>
      <p>
        Physical Mail:
        <br />
        No 123, 127, Kattoor Rd,
        <br />
        Pappanaickenpalayam,
        <br />
        Coimbatore,
        <br />
        Tamil Nadu 641037
      </p>

      <h2>Terms &amp; Conditions</h2>
      <p>
        Please read the Terms and the Privacy Policy of the Company (“Privacy
        Policy”) with respect to registration with us, the use of the
        Application, Website, Services carefully before using the Application,
        Website, Services. In the event of any discrepancy between the Terms and
        any other policies with respect to the Application or Website or
        Services, the provisions of the Terms shall prevail.
      </p>

      <p>
        Your use/access/browsing of the Application or Website or registration
        (with or without payment/with or without subscription) through any means
        shall signify Your acceptance of the Terms and Your agreement to be
        legally bound by the same.
      </p>

      <p>
        If you do not agree with the Terms or the Privacy Policy, please do not
        use the Application or Website or avail the Services. Any access to our
        Services/Application through registrations/subscription is non
        transferable.
      </p>

      <h3>Proprietary Information</h3>
      <p>
        All information, content, materials, trademarks, service marks, trade
        names, and trade secrets, including but not limited to software, text,
        images, graphics, video, scripts, and audio, contained within the
        imageofachild.ai website, mobile application, web application, and
        worksheets are proprietary property of Image of a Child ("Company"). No
        Proprietary Information may be copied, downloaded, reproduced, modified,
        republished, uploaded, posted, transmitted, or distributed in any manner
        without prior written permission from the Company. Nothing on this
        website, application, services, or products shall be construed as
        conferring a license or any other right, interest, or title to or in any
        intellectual property rights belonging to the Company to the user. You
        may own the medium on which the information, content, or materials
        reside, but the Company retains full and complete title to such
        information, content, or materials and all intellectual property rights
        embedded therein.
      </p>

      <h3>Use Restrictions</h3>
      <p>
        Your use of our website, web application, mobile application and it’s
        services ("Services") is intended solely for personal and non-commercial
        purposes. Any use of the Application, Website, Services, or their
        contents other than for personal purposes, is strictly prohibited. Your
        personal and non-commercial use of this Application, Website, and/or our
        Services shall be subject to the following restrictions:
      </p>
      <ul>
        <li>
          You agree not to modify, copy, distribute, transmit, display, perform,
          reproduce, publish, license, create derivative works from, transfer,
          or sell any information, content, images, videos or services obtained
          from or through this Application, Website, or Services without our
          prior written permission.
        </li>
        <li>
          You agree not to use the Services for any illegal purpose or in any
          manner inconsistent with these Terms of Use.
        </li>
        <li>
          You agree not to access or use the Application, Website, or Services
          in any way that could damage, disable, overburden, or impair any
          server or network connected to the Application, Website, or Services,
          or interfere with any other party's use and enjoyment of the
          Application, Website, or Services.
        </li>
        <li>
          You agree not to attempt to gain unauthorized access to any part of
          the Application, Website, Services, or any systems or networks
          connected to the Application, Website, or Services, by hacking,
          password "mining," or any other illegitimate means.
        </li>
        <li>
          You agree not to use any robot, spider, scraper, or other automated
          means to access the Application, Website, or Services for any purpose
          without our express written permission.
        </li>
        <li>
          You agree not to transmit any viruses, worms, defects, Trojan horses,
          or any items of a destructive nature through your use of the
          Application, Website, or Services.
        </li>
        <li>
          You agree not to use the Application, Website, or Services to violate
          the security of any computer network, crack passwords, or security
          encryption codes, disrupt or interfere with the security of, or
          otherwise cause harm to, the Application, Website, or Services or
          their content.
        </li>
      </ul>

      <h3>Accuracy and Changes</h3>
      <p>
        We have made every effort to ensure that the Application or Website
        provide accurate and up-to-date information. However, despite our best
        efforts, errors may occur. Imageofachild disclaims any responsibility
        for inaccuracies or errors in the information contained in the
        Application. We welcome feedback from users to improve the Application
        and its content, making it more error-free and user-friendly.
      </p>

      <p>
        Imageofachild reserves the right to make changes, corrections,
        additions, or withdrawals of content at any time without prior notice.
        Neither Imageofachild nor any third parties provide any warranty or
        guarantee regarding the accuracy, timeliness, performance, completeness,
        or suitability of the information and materials available on the
        Application or Website for any particular purpose. You acknowledge that
        such information and materials may contain inaccuracies or errors, and
        we expressly exclude liability for any such inaccuracies or errors to
        the fullest extent permitted by law.
      </p>

      <h3>Personnel Training and Quality Assurance</h3>
      <p>
        The Company strives to train personnel engaged in sales and service to
        ensure quality control. However, it does not provide warranties or
        representations regarding the quality or competence of such personnel.
        The Company is not liable for any misconduct by personnel. User feedback
        regarding personnel is welcome, and the Company reserves the right to
        take appropriate action based on such feedback.
      </p>

      <h3>Registration and Account Management</h3>
      <ul>
        <li>
          <strong>Account Registration:</strong> To access the Services and
          utilize the Application, parents or legal guardians (referred to as
          "You") must register with the Application and maintain an account. You
          will need to provide certain information and details, including Your
          child name, your mobile number, email address, child’s DOB, payment
          information (credit/debit card details if required), and any other
          necessary information as specified by the Application.
        </li>
        <li>
          <strong>Accuracy of Information:</strong> It is Your responsibility to
          ensure that all account information provided is accurate, complete,
          and up-to-date.
        </li>
        <li>
          <strong>Account Security:</strong> You are responsible for maintaining
          the the confidentiality of Your account information and for all
          activities that occur under Your account. You agree to (a) ensure that
          You log out from Your account at the end of each session; and (b)
          promptly notify the Company of any unauthorized use of Your account.
          If there is reason to believe that there is a security breach or
          misuse of Your account, the Company may request You to change Your
          password or suspend Your account without any liability, for such
          period as deemed necessary.
        </li>
        <li>
          <strong>External Factors:</strong> Your ability to use Your account
          may depend on external factors such as internet service providers and
          network network availability. The Company cannot guarantee continuous
          accessibility to the Application. In addition to the disclaimers in
          the Terms, the Company shall not be liable for any damages resulting
          from Your inability to access the Application or Services at any time.
        </li>
      </ul>

      <h3>Eligibility and Use of Application</h3>
      <ul>
        <li>
          <strong>Eligibility:</strong> Only parents or legal guardians who are
          competent to contract under the Indian Contract Act, 1872, are
          eligible to register for the Application and utilize all Our products
          or Services. Minors, un-discharged insolvents, or any other persons
          not capable of contracting are not eligible to register for Our
          products or Services. If You are a minor and wish to use Our products
          or Services, such use must be supervised and authorized by Your legal
          guardian or parents, who agree to these Terms. By using the products
          or Services, You warrant that all the data provided is accurate and
          complete, and that the child using the Application has obtained the
          consent of their parent/legal guardian.
        </li>
        <li>
          <strong>Responsibility for Use by Minors:</strong> If a minor utilizes
          the Application, it is assumed that they have obtained the consent of
          their legal guardian or parents for such use. The Company shall not be
          responsible for any consequences arising from the misuse of Our
          Application or any of Our products or Services by any person,
          including minors, who register for the Services provided.
        </li>
        <li>
          <strong>Termination of Subscription:</strong> The Company reserves the
          right to right to terminate Your subscription and/or refuse to provide
          You with access to the products or Services if it is discovered that
          You are under the age of 18 (eighteen) years and the consent to use
          the products or Services has not been provided by Your parent/legal
          guardian, or if any information provided by You is inaccurate. It is
          Your sole responsibility to ensure that You meet the required
          eligibility criteria.
        </li>
        <li>
          <strong>Parental Consent:</strong> Any persons under the age of 18
          (eighteen) should seek the consent of their parents/legal guardians
          before providing any information about themselves or their parents and
          other family members on the Application.
        </li>
      </ul>

      <h3>Indemnification and Liability</h3>
      <ul>
        <li>
          <strong>Indemnification:</strong> You agree to defend, indemnify, and
          hold harmless the Company, its officers, directors, employees, and
          agents from and against any and all claims, damages, obligations,
          losses, liabilities, costs, or debts, including attorney’s fees,
          arising from:
        </li>
        <ul>
          <li>Your use of and access to the Application/Website/Services;</li>
          <li>
            Your violation of any term of these Terms or any other policy of the
            Company;
          </li>
          <li>
            Your violation of any third party right, including without
            limitation, any copyright, property, or privacy right; or
          </li>
          <li>
            Any claim that Your use of the Application/Website/Services has
            caused damage to a third party. This defense and indemnification
            obligation will survive these Terms.
          </li>
        </ul>
        <li>
          <strong>Limitation of Liability:</strong> In no event shall the
          Company, its officers, directors, employees, or agents be liable to
          You for any special, incidental, indirect, consequential, or punitive
          damages whatsoever, including those resulting from loss of use, data,
          or profits, or any other claim arising out of or in connection with
          Your use of, or access to, the Application.
        </li>
        <li>
          <strong>Injunctive Relief:</strong> In the event of Your breach of
          these Terms, You agree that the Company will be irreparably harmed and
          may not have an adequate remedy in money or damages. The Company shall
          therefore be entitled in such event to obtain an injunction against
          such a breach from any court of competent jurisdiction. The Company’s
          right to obtain such relief shall not limit its right to obtain other
          remedies.
        </li>
        <li>
          <strong>Suspension or Termination:</strong> Any violation by You of
          the terms of this Clause may result in immediate suspension or
          termination of Your Accounts, apart from any legal remedy that the
          Company can avail. In such instances, the Company may also disclose
          Your Account Information if required by any governmental or legal
          authority. You understand that the violation of these Terms could also
          result in civil or criminal liability under applicable laws.
        </li>
        <li>
          <strong>Governing Law and Jurisdiction:</strong> The Terms shall be
          governed by and construed in accordance with the laws of India,
          without regard to conflict of law principles. Further, the Terms shall
          be subject to the exclusive jurisdiction of the competent courts
          located in Bangalore, and You hereby submit to and accept the
          jurisdiction of such courts.
        </li>
        <li>
          <strong>Changes to Terms and Application:</strong> The Company
          reserves the right to change, modify, suspend, or discontinue any
          aspect(s), features, or functionality of the Application or the
          Services as it deems fit at any time without notice. The Company also
          reserves the right to amend these Terms from time to time without
          prior notice to you. The Company makes no commitment, express or
          implied, to maintain or continue any aspect of the Application. You
          agree that the Company shall not be liable to You or any third party
          for any modification, suspension, or discontinuance of the
          Application/Services. All prices are subject to change without notice.
        </li>
      </ul>

      <h3>DISCLAIMER</h3>
      <p>
        THIS APPLICATION, THE SERVICES, AND ALL RELATED PRODUCTS ARE PROVIDED ON
        AN "AS IS" BASIS WITH ALL FAULTS AND WITHOUT ANY WARRANTY OF ANY KIND.
        THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD
        TO THE APPLICATION, SERVICES, AND PRODUCTS, INCLUDING WITHOUT
        LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY, TIMELINESS,
        PERFORMANCE, COMPLETENESS, SUITABILITY, AND NON-INFRINGEMENT.
      </p>
      <p>
        ADDITIONALLY, THE COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING
        OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS APPLICATION,
        SERVICES, OR PRODUCTS. YOUR USE OF ANY INFORMATION OR MATERIALS PROVIDED
        BY US IS ENTIRELY AT YOUR OWN RISK, FOR WHICH WE SHALL NOT BE LIABLE. IT
        IS YOUR RESPONSIBILITY TO ENSURE THAT THE SERVICES PROVIDED BY US MEET
        YOUR SPECIFIC REQUIREMENTS.
      </p>

      <h3>General Provisions:</h3>
      <p>
        <strong>Notice:</strong> All notices served by the Company shall be
        provided via email to Your account or as a general notification on the
        Application. Any notice to be provided to the Company should be sent to
        contact@imageofachild.ai.
      </p>
      <p>
        <strong>Entire Agreement:</strong> The Terms, along with the Privacy
        Policy, and any other guidelines made applicable to the Application from
        time to time, constitute the entire agreement between the Company and
        You with respect to Your access to or use of the Application, Website,
        and the Services thereof.
      </p>
      <p>
        <strong>Assignment:</strong> You cannot assign or otherwise transfer
        Your obligations under the Terms, or any right granted hereunder to any
        third party. The Company’s rights under the Terms are freely
        transferable by the Company to any third parties without the requirement
        of seeking Your consent.
      </p>
      <p>
        <strong>Severability:</strong> If, for any reason, a court of competent
        jurisdiction finds any provision of the Terms, or portion thereof, to be
        unenforceable, that provision shall be enforced to the maximum extent
        permissible so as to give effect to the intent of the parties as
        reflected by that provision, and the remainder of the Terms shall
        continue in full force and effect.
      </p>
      <p>
        <strong>Waiver:</strong> Any failure by the Company to enforce or
        exercise any provision of the Terms, or any related right, shall not
        constitute a waiver by the Company of that provision or right.
      </p>
      <p>
        <strong>Relationship:</strong> You acknowledge that Your participation
        on the Application does not make You an employee, agent, partner, joint
        venture, or franchisee of the Company.
      </p>
      <p>
        <strong>Acknowledgment:</strong> The Company provides these Terms so
        that You are aware of the terms that apply to Your use of the
        Website/Application and Services. You acknowledge that the Company has
        given You a reasonable opportunity to review these Terms and that You
        have agreed to them.
      </p>

      <h3>Feedback:</h3>
      <p>
        Any feedback You provide with respect to the Application shall be deemed
        to be non-confidential. The Application shall be free to use such
        information on an unrestricted basis. Further, by submitting the
        feedback, You represent and warrant that (i) Your feedback does not
        contain confidential or proprietary information of You or of third
        parties; (ii) the Company is not under any obligation of
        confidentiality, express or implied, with respect to the feedback; (iii)
        the Application may have something similar to the feedback already under
        consideration or in development; and (iv) You are not entitled to any
        compensation or reimbursement of any kind from the Company for the
        feedback under any circumstances, unless specified.
      </p>
      <p>
        Under no circumstances shall the Company be held responsible in any
        manner for any content provided by other users, even if such content is
        offensive or hurtful. Please exercise caution while accessing the
        Application.
      </p>

      <h3>Customer Care:</h3>
      <p>
        We make all best endeavors to provide You with a pleasant experience. In
        the unlikely event that You face any issues, please contact us at
        contact@imageofachild.ai.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
