import React from "react";
import style from "./scss/footer.module.scss";
// import logo from "assets/images/IOCLogo.png";
import footerMap from "assets/images/footerMap.png";
import fb from "assets/images/FacebookLogo.svg";
import insta from "assets/images/InstagramLogo.svg";
import linkedIn from "assets/images/LinkedinLogo.svg";
import { Image, Grid } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const Footer = () => {
  let history = useHistory();
  const handleRedirection = (path) => {
    history.push(`/${path}`);
  };
  return (
    <>
      <Grid className={style.footer}>
        <Grid.Column
          computer={5}
          mobile={16}
          className={style.footerColumn}
          textAlign="center"
        >
          <div className={style.footerDiv1}>
            <div className={style.SchoolAge}>
              {/* <div className={style.ioacContent}>
                <p>SchoolAge</p>
              </div> */}
              {/* <div className={style.ioacImage}>
                <Image className={style.logoImg} src={logo} />
              </div> */}
            </div>
            <div className={style.part2}>
              <div className={style.ioacContent2}>
                <p>Write to us @</p>
              </div>
              <div className={style.ioacLink}>
                <p>info@schoolage.ai</p>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column
          computer={5}
          mobile={16}
          className={style.footerColumn}
          textAlign="left"
        >
          <div className={style.footerDiv2}>
            <div className={style.contact}>CONTACT</div>
            <div className={style.contactDetails}>
              <div>
                <a className={style.contactContent}>9363614106</a>
              </div>
              <div>
                <a className={style.contactContent}>info@schoolage.ai</a>
              </div>
            </div>
            <div className={style.clr15}></div>
            <div className={style.socialMedia}>SOCIAL MEDIA</div>
            <div className={style.socialMediaLogo}>
              <div className={style.logo}>
                <Image src={fb} />
              </div>
              <div className={style.logo}>
                <Image src={insta} />
              </div>
              <div className={style.logo}>
                <Image src={linkedIn} />
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <div className={style.footerDiv3}>
            <div className={style.visitUs}>
              VISIT US
              <p className={style.visitContent}>
                No. 362/7, Third Floor, 16th Main, 4th T Block East, Jayanagar,
                Bengaluru, Karnataka 560041
              </p>
            </div>
            <div>
              <Image className={style.footerMap} size="small" src={footerMap} />
            </div>
          </div>
        </Grid.Column>
      </Grid>
      <div className={style.footerMessage}>
        <span>Copyrights reserved</span>|
        <span
          className={style.menu}
          onClickCapture={() => handleRedirection("privacyPolicy")}
        >
          Privacy policy
        </span>
        |
        <span
          className={style.menu}
          onClickCapture={() => handleRedirection("termsAndConditions")}
        >
          Terms & Conditions
        </span>
      </div>
    </>
  );
};

export default Footer;
