import React from "react";
import style from "./defaultLoader.module.scss";

const DefaultLoader = () => {
  return (
    <>
      <div className={style.iocLogoWrapper}>
        <img className={style.iocLogo} src="/logo.webp" />
        <img className={style.iocLogoLoader} src="/logoCircle.webp" />
      </div>
    </>
  );
};

export default DefaultLoader;
