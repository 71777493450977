/* eslint-disable react/jsx-max-props-per-line */
import React from "react";
import styles from "./scss/ProgrameMobile.module.scss";
// import programeBg from "assets/images/home/mobilePrograme.webp";
import { programContent } from "./ProgramsData";
import { Container, Header, Image } from "semantic-ui-react";
const ProgramsMobile = () => {
  return (
    <>
      <div className={styles.svgWrapper}>
        {programContent.map((ele) => (
          <>
            <Container className={styles.programMobWrapper}>
              <Header textAlign="center">{ele.name}</Header>
              <Image src={ele.image} />
              <div>{ele.content}</div>
            </Container>
          </>
        ))}
      </div>
    </>
  );
};
export default ProgramsMobile;
