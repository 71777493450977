import program1 from "assets/images/home/programImg1.webp";
import program2 from "assets/images/home/programImg2.webp";
import program3 from "assets/images/home/programImg3.webp";
import program4 from "assets/images/home/programImg4.webp";
import program5 from "assets/images/home/programImg5.webp";
import program6 from "assets/images/home/programImg6.webp";
import program7 from "assets/images/home/programImg7.webp";
import program8 from "assets/images/home/programImg8.webp";
import program9 from "assets/images/home/programImg9.webp";
import program10 from "assets/images/home/programImg10.webp";
import program11 from "assets/images/home/programImg11.webp";

export const programContent = [
  {
    id: 1,
    name: "Sounds For Life and Language For Life - Grades 3 to 8",
    content: `Empowering students in grades 3 to 8 with essential communication skills for the digital era, our 'Sounds For Life' and 'Language For Life' programs enhance verbal and auditory capabilities, ensuring mastery in various interaction modes that are critical as technology evolves. By fostering linguistic agility and auditory processing, these programs enable learners to express themselves more effectively and understand others more deeply. The curriculum is designed to adapt to individual learning styles, promoting confidence and competence in communication across diverse platforms and cultural contexts`,
    image: program1,
    imgPosition: "right",
  },
  {
    id: 2,
    name: "Creativity for Life -  Grades 6 to 9",
    content: `Designed for students in grades 6 to 9, 'Creativity for Life' fosters innovative thinking and problem-solving skills that technology cannot replicate, preparing young minds to contribute uniquely and valuably in the future workforce. Through project-based learning and collaborative challenges, students are encouraged to think outside the box and develop solutions to complex problems. This program emphasizes the importance of creativity in personal and professional success, nurturing a generation of leaders who are prepared to drive innovation in an ever-changing world.`,
    image: program2,
    imgPosition: "left",
  },
  {
    id: 3,
    name: "Creative Science For Life - Grades 3 to 10",
    content: `"Creative Science for Life" is an innovative science program designed for Grades 3 to 10, focusing on experiential learning through problem-solving, ideation, and innovation. Students engage with scientific concepts in dynamic ways, including virtual labs, nature-based stories, and the observation of natural phenomena. The program emphasizes creative thinking and connects scientific theories to practical, real-world applications. By integrating traditional learning with interactive methods, it makes science both relevant and engaging. This approach nurtures confident, creative thinkers who are prepared to address real-world challenges.`,
    image: program3,
    imgPosition: "right",
  },
  {
    id: 4,
    name: "Creative Social-Science For Life - Grades 3 to 10",
    content: `"Creative Social-Science for Life" is more than just a program; it’s a transformative journey for students in Grades 3 to 8. This initiative stands out because it seamlessly blends academic rigor with real-world application, making subjects like History, Geography, and Civics not only informative but deeply relevant to students’ lives. By emphasizing active learning through hands-on activities, case studies, and field visits, the program ensures that students don’t just learn about the past or the structure of society—they understand how to apply this knowledge to build a more empathetic and just world.`,
    image: program4,
    imgPosition: "left",
  },
  {
    id: 5,
    name: "Creative Math for Life - Grade 3 to 10",
    content: `"Creative Math for Life" is a dynamic program for Grades 3 to 8 that deepens students' understanding of mathematical theories, theorems, and their real-world applications. It connects math to modern challenges and encourages students to discover mathematical concepts in nature through observations and experiments. The program fosters innovation and deep thinking, making math an exciting and relevant subject. This approach nurtures confident, creative thinkers who are prepared to use math to address complex problems in the emerging world.`,
    image: program5,
    imgPosition: "right",
  },
  {
    id: 6,
    name: "Humanize For Life - Grades 8 to 10",
    content: `Targeting grades 8 to 10, 'Humanize For Life' cultivates empathy and ethical reasoning, essential for using technology responsibly. This program ensures students grow to make considerate, informed decisions in a tech-saturated world. By exploring the ethical dimensions of technology and its impact on society, students learn to approach digital innovations with a critical, humane perspective. The curriculum includes case studies and interactive simulations that reinforce the importance of ethics in the digital age, preparing students to be conscientious global citizens.`,
    image: program6,
    imgPosition: "left",
  },
  {
    id: 7,
    name: "Coding for Life - Grades 3 to 10",
    content: `From grades 3 to 10, 'Coding for Life' demystifies technology, transforming students from passive consumers to active creators. This program lays the foundational skills for building digital solutions and understanding the underlying mechanisms of tech innovations. By engaging in hands-on coding projects, students gain practical experience in software development and computational thinking, fostering skills that are crucial for success in the digital economy. The curriculum is designed to be inclusive, catering to beginners and advanced students alike, ensuring everyone can find a path in the evolving landscape of technology`,
    image: program7,
    imgPosition: "right",
  },
  {
    id: 8,
    name: "Mindfulness for Life - Grades 7 to 10",
    content: `Offering vital tools for stress management and effective learning, 'Mindfulness for Life' supports students in grades 7 to 10 in navigating the challenges of modern life, enhancing focus, and fostering a balanced mental well-being. Through guided practices and reflective activities, students learn to manage stress, regulate emotions, and maintain mental health. The program also explores the science behind mindfulness, providing students with a deeper understanding of its benefits and applications. This holistic approach not only enhances academic performance but also improves overall life satisfaction`,
    image: program8,
    imgPosition: "left",
  },
  {
    id: 9,
    name: "Nature For Life -  Grades 4 to 10",
    content: `Spanning grades 4 to 10, 'Nature For Life' connects students with the environment, instilling a deep sense of sustainability and responsibility towards our planet. This program enriches understanding of ecological interdependencies and the importance of environmental stewardship. Through field trips, hands-on science experiments, and community projects, students explore natural ecosystems, learning about conservation and biodiversity. The curriculum fosters a lifelong connection with nature, encouraging students to become active participants in preserving our environment`,
    image: program9,
    imgPosition: "right",
  },
  {
    id: 10,
    name: "Man and Machines in Life - Grades 5 to 10",
    content: `For students in grades 5 to 10, 'Man and Machines in Life' explores the symbiotic relationship between humans and technology, preparing them to work effectively alongside machines and understand the implications of technological integration in everyday life. The program covers topics from robotics to smart technologies, providing insights into how these tools can enhance human capabilities. Interactive projects and collaborative robotics challenges engage students practically, enhancing their technical skills and critical thinking.`,
    image: program10,
    imgPosition: "left",
  },
  {
    id: 10,
    name: "AI For Life - Grades 6 to 10",
    content: `Aimed at grades 6 to 10, 'AI For Life' immerses students in the world of artificial intelligence, equipping them with the knowledge and tools to innovate and lead in this transformative technology field, ensuring they are ready to shape and succeed in an AI-driven future. Through real-world applications and hands-on activities, students explore AI technologies, learning about machine learning, neural networks, and ethical implications. This program not only prepares students for technological careers but also fosters responsible innovation and ethical decision-making in AI usage.`,
    image: program11,
    imgPosition: "right",
  },
];
