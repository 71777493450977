/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import EarleyYears from "../../globals/components/EarlyYears";
import Programs from "./Programs";
// import ReviewSection from "./ReviewSection";
import platformContentImg from "assets/images/home/platformFetureImg.webp";
import banner3 from "assets/images/home/banner3Alt.webp";
import arrowLeft from "assets/images/home/ArrowCircleLeft.webp";
import arrowright from "assets/images/home/ArrowCircleRight.webp";
import whySchool1 from "assets/images/home/whySchool1.webp";
import whySchool2 from "assets/images/home/whySchool2.webp";
import whySchool3 from "assets/images/home/whySchool3.webp";
import whySchool4 from "assets/images/home/whySchool4.webp";
import whySchool5 from "assets/images/home/whySchool5.webp";
import whySchool6 from "assets/images/home/whySchool6.webp";
import comingSoon from "assets/images/comingSoon.webp";
import styles from "./scss/HomeScreen.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import PartnerSlider from "./PartnerSlider";
import {
  Icon,
  GridColumn,
  Grid,
  Segment,
  Header,
  Modal,
  ModalDescription,
  ModalActions,
  Button,
  Image,
  Container,
} from "semantic-ui-react";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import Slider from "react-slick";
import HTMLReactParser from "html-react-parser";
import RegistrationForm from "../product/RegistrationForm";
// import Features from "./Features";

const HomeScreen = () => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [activeItem, setActiveItem] = useState(1);
  const [regModalOpen, setRegModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [activePlatformItem, setPlatformActiveItem] = useState(1);
  const sliderRef1 = useRef();
  const sliderRef2 = useRef();
  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  const ioacSpecialContent = [
    {
      id: 1,
      title: "Cultivation of Future-Proof Skills",
      image: whySchool1,
      content: [
        "With an eye towards the horizons of the 22nd and 23rd centuries, SchoolAge’s curriculum is meticulously designed to nurture not merely academic prowess but also philosophical depth, aesthetic acumen, design thinking capacities, and creative dynamism.",
        "These capabilities are strategically developed to endure through the evolving landscapes of future decades, ensuring long-term relevance and utility.",
      ],
    },
    {
      id: 2,
      title: "Precision Learning through AI",
      image: whySchool2,
      content: [
        "Leveraging cutting-edge artificial intelligence, SchoolAge offers a bespoke educational experience that adapts in real-time to the unique learning curves and preferences of each student.",
        "This AI-enhanced approach provides a dynamic educational journey, continually fine-tuning content delivery for optimized learning efficacy",
      ],
    },
    {
      id: 3,
      title: "Innovative Virtual Atelier",
      image: whySchool3,
      content: [
        "The platform features an exclusive Virtual Atelier, a digital nexus where students engage in collaborative and exploratory learning. This virtual workshop offers a secure and stimulating environment for creative exploration and intellectual experimentation.",
        "It serves as a conduit for transcending conventional educational confines, promoting an expansive, interactive learning experience.",
      ],
    },
    {
      id: 4,
      title: "Empirical and Analytical Rigor",
      image: whySchool4,
      content: [
        "Constructed on a foundation of empirical research and data analytics, SchoolAge is the brainchild of preeminent scholars in learning sciences. This data-driven approach ensures that educational strategies are not only pioneering but are substantiated by scientific validation.",
        "This rigorous scientific underpinning guarantees that the pedagogical techniques are both groundbreaking and result-oriented.",
      ],
    },
    {
      id: 5,
      title: "Technological Sophistication",
      image: whySchool5,
      content: [
        "The underlying structure of SchoolAge is a technologically advanced platform, meticulously engineered with over a million lines of code. This technological prowess facilitates a highly scalable and interactive learning environment.",
        "This substantial investment in technology enhances educational delivery, setting a new standard in digital learning platforms.",
      ],
    },
    {
      id: 6,
      title: "Synergistic Educational Philosophies",
      image: whySchool6,
      content: [
        "SchoolAge skillfully blends diverse educational philosophies—such as Reggio Emilia, Montessori, International Baccalaureate, and Gurukul—with modern theories like Gardner's Multiple Intelligences.",
        "This integrative approach bridges tradition and innovation while fostering a well-rounded set of cognitive and socio-emotional skills.",
      ],
    },
  ];
  const educationModelContent = [
    {
      id: 1,
      title: `Synergistic Pedagogy`,
      content: [
        `SchoolAge – The Catalyst School synthesizes a rich tapestry of educational philosophies, blending the best of Reggio Emilia, Montessori, International Baccalaureate, and Gurukul systems with modern theories like Howard Gardner’s Multiple Intelligences. This integrative approach fosters a comprehensive development of cognitive, emotional, and social skills, ensuring that students are not only academically proficient but also equipped to contribute meaningfully to society. SchoolAge creates a learning environment where every aspect of a student’s growth is nurtured and celebrated.`,
      ],
    },
    {
      id: 2,
      title: `AI-Driven Personalized Learning`,
      content: [
        `At SchoolAge, learning is deeply personalized, with AI at the core of its adaptive approach. The platform tailors educational pathways to each student's unique needs, pace, and interests, continuously evolving to provide the right support at the right time. This personalized journey empowers students to take control of their education, enhancing engagement and fostering mastery. SchoolAge transforms learning into a dynamic, customized experience that unlocks each student’s full potential.`,
      ],
    },
    {
      id: 3,
      title: `Expert-Designed Curriculum`,
      content: [
        `SchoolAge collaborates with leading educators, industry experts, and practitioners to curate a curriculum that is both forward-thinking and rigorous. The content reflects the latest advancements and best practices, ensuring students receive an education that is relevant and impactful. This expertly designed curriculum equips learners with the knowledge and skills needed to excel in a rapidly changing world, making SchoolAge a comprehensive and cutting-edge educational solution.`,
      ],
    },
    {
      id: 4,
      title: `Philosophically Enriching and Aesthetically Inspiring`,
      content: [
        `SchoolAge challenges students to engage with complex ideas and diverse perspectives through content that is philosophically enriching and aesthetically inspiring. The platform encourages intellectual curiosity and critical thinking, creating an educational journey that is as visually and mentally stimulating as it is profound. This approach sharpens analytical abilities and deepens students' appreciation for the beauty of knowledge and inquiry, making learning a truly enriching experience.`,
      ],
    },
    {
      id: 5,
      title: `Foundation for Lifelong Learning`,
      content: [
        `SchoolAge is committed to fostering lifelong learners by instilling the skills, mindset, and adaptability necessary for continuous growth in an ever-evolving world. The platform emphasizes curiosity, critical thinking, and resilience, preparing students to navigate and thrive in a future that demands ongoing learning and self-improvement. With SchoolAge, education is not just a phase but a lifelong pursuit of knowledge and personal development.`,
      ],
    },
  ];

  const platFormContent = [
    {
      id: 1,
      title: `Virtual Ateliers and Labs`,
      content: [
        `SchoolAge’s Virtual Ateliers and Labs offer immersive, hands-on experiences in a digital environment, where students can experiment, innovate, and apply their knowledge in real-world scenarios. These interactive spaces replicate various disciplines, providing a safe and dynamic setting for learners to deepen their understanding and refine their skills. With real-time feedback and collaborative projects, SchoolAge’s Virtual Ateliers make experiential learning accessible and engaging, fostering creativity and practical skills essential for the future.`,
      ],
    },
    {
      id: 2,
      title: `Dynamic Learning Profiles`,
      content: [
        `SchoolAge creates Dynamic Learning Profiles that document each student’s educational journey, capturing growth, achievements, and areas for improvement. These profiles provide a comprehensive view of a learner’s progress, enabling personalized guidance and support throughout their development. By analyzing these profiles, educators can tailor their teaching strategies to meet the unique needs of each student. SchoolAge ensures that every learner’s development is fully understood and supported for continuous improvement.`,
      ],
    },
    {
      id: 3,
      title: `Advanced AI Analytics`,
      content: [
        `Leveraging advanced AI analytics, SchoolAge provides deep insights into student performance, learning patterns, and potential challenges. The platform uses real-time data to offer educators actionable information, enhancing teaching effectiveness and student outcomes. These analytics help identify knowledge gaps, predict academic success, and personalize learning experiences based on individual needs. SchoolAge empowers educators with the tools to make data-driven decisions that drive superior educational results.`,
      ],
    },
    {
      id: 4,
      title: `Highly Scalable and Interactive`,
      content: [
        `SchoolAge is engineered to be highly scalable and interactive, accommodating educational institutions of all sizes while delivering a consistently engaging learning experience. The platform’s scalability allows for seamless expansion without compromising performance, and its interactive elements—such as simulations, discussions, and real-time assessments—keep learners motivated and involved. SchoolAge’s scalability ensures that institutions can grow and adapt their educational offerings effortlessly, providing students with a dynamic, rich learning experience.`,
      ],
    },
    {
      id: 5,
      title: `Seamless Integration`,
      content: [
        `SchoolAge offers seamless integration with existing educational systems, ensuring a smooth adoption process for institutions. The platform is designed to work harmoniously with various Learning Management Systems (LMS), data repositories, and other educational technologies, minimizing disruption while maximizing efficiency. SchoolAge’s interoperability allows institutions to enhance their current infrastructure with advanced tools and resources without the need for an extensive overhaul. This seamless integration makes SchoolAge a valuable and easy addition to any educational ecosystem.`,
      ],
    },
    {
      id: 6,
      title: ` Innovative Learning Strategies`,
      content: [
        `SchoolAge leads in developing innovative learning strategies that blend traditional educational methods with modern technology to optimize student outcomes. The platform offers a unique combination of interactive content, personalized learning paths, and real-time feedback, creating a holistic approach to education that resonates with today’s learners. These strategies meet the diverse needs of students, fostering deep understanding, critical thinking, and practical application of knowledge. SchoolAge empowers educators to implement teaching methods that not only engage students but also enhance their overall learning experience.`,
      ],
    },
  ];

  useEffect(() => {
    document.title = "Home | SchoolAge ";
    setwindowWidth(window.innerWidth);
  }, []);

  const renderFeatureMobileView = () => (
    <svg
      width="392"
      height="848"
      viewBox="0 0 392 848"
      xmlns="http://www.w3.org/2000/svg"
    >
      <foreignObject width="392" height="848">
        {/* <div className={styles.featureMobile}>
          <img src={frame1mbl} />
          <img src={frame2mbl} />
        </div> */}
      </foreignObject>
      <foreignObject width="392" height="848">
        <div className={styles.featureContentMobile}>
          <div className={styles.eduModalMobile}>
            <div className={styles.educontentMblWrapper}>
              {/* <img src={rectangle} /> */}
              <Segment className={styles.eduSegemtMbl}>
                <Header className={styles.eduSegmentMblHeader}>
                  Education Model
                </Header>
                <div className={styles.eduContent}>
                  {/* <img src={vector} className={styles.vectorImgMbl} /> */}
                  <Slider
                    {...settings}
                    ref={sliderRef1}
                    className={styles.slider}
                  >
                    {educationModelContent.map((data) => {
                      return (
                        <div key={data.id} className={styles.sliderDiv}>
                          <p className={styles.eduActiveHeaderMbl}>
                            {data.title}
                          </p>
                          <ul className={styles.ulTag}>
                            {data.content.map((ele) => (
                              <li key={ele.title}>{HTMLReactParser(ele)}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    })}
                  </Slider>
                  <div className={styles.divWrapper}>
                    <div className={styles.childDiv}>
                      <LazyLoadImage
                        onClick={() => sliderRef1.current.slickPrev()}
                        className={styles.leftArrowImage}
                        src={arrowLeft}
                      />
                      <LazyLoadImage
                        onClick={() => sliderRef1.current.slickNext()}
                        className={styles.rightArrowImage}
                        src={arrowright}
                      />
                    </div>
                  </div>
                </div>
              </Segment>
            </div>
          </div>
          <div className={styles.eduModalMobile}>
            <div className={styles.educontentMblWrapper}>
              {/* <img src={rectangle} /> */}
              <Segment className={styles.eduSegemtMbl}>
                <Header className={styles.eduSegmentMblHeader}>Platform</Header>
                <div className={styles.eduContent}>
                  {/* <img src={vector} className={styles.vectorImgMbl} /> */}
                  <Slider
                    {...settings}
                    ref={sliderRef2}
                    className={styles.slider}
                  >
                    {platFormContent.map((data) => {
                      return (
                        <div key={data.id} className={styles.sliderDiv}>
                          <p className={styles.eduActiveHeaderMbl}>
                            {data.title}
                          </p>
                          <ul className={styles.ulTag}>
                            {data.content.map((ele) => (
                              <li key={ele.title}>{HTMLReactParser(ele)}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    })}
                  </Slider>
                  <div className={styles.divWrapper}>
                    <div className={styles.childDiv}>
                      <LazyLoadImage
                        onClick={() => sliderRef2.current.slickPrev()}
                        className={styles.leftArrowImage}
                        src={arrowLeft}
                      />
                      <LazyLoadImage
                        onClick={() => sliderRef2.current.slickNext()}
                        className={styles.rightArrowImage}
                        src={arrowright}
                      />
                    </div>
                  </div>
                </div>
              </Segment>
            </div>
          </div>
        </div>
      </foreignObject>
    </svg>
  );

  const renderFeatureDesktopView = () => {
    const currentPlatformObj = platFormContent.find(
      (ele) => ele.id === activePlatformItem
    );
    const currentEduObj = educationModelContent.find(
      (ele) => ele.id === activeItem
    );
    return (
      <div className={styles.featureContainer}>
        <Grid centered columns={2} className={styles.featureGrid}>
          <GridColumn className={styles.gridColumn}>
            <div className={styles.eduWrapper}>
              <div className={styles.eduContentWrapper}>
                <div placeholder className={styles.eduSegmentWrapper}>
                  <div className={styles.eduSegment1}>
                    <Header className={styles.eduSegmentHeader}>
                      Education Model
                    </Header>
                    <ol>
                      {educationModelContent.map((ele) => (
                        <div
                          key={ele.title}
                          className={
                            activeItem === ele.id
                              ? styles.eduActiveTitle1
                              : styles.eduTitle1
                          }
                          onClickCapture={() => setActiveItem(ele.id)}
                        >
                          <li>{ele.title}</li>
                        </div>
                      ))}
                    </ol>
                  </div>
                  <Segment
                    className={`${styles.eduSegment2} ${styles.segment2Div}`}
                  >
                    <p className={styles.eduActiveHeader}>
                      {currentEduObj.title}
                    </p>
                    <div>
                      {currentEduObj.content.map((ele) => (
                        <div key={ele.title}>{HTMLReactParser(ele)}</div>
                      ))}
                    </div>
                  </Segment>
                </div>
              </div>
            </div>
          </GridColumn>
          <GridColumn className={styles.gridColumn}>
            <div className={styles.eduWrapper}>
              <div className={styles.eduContentWrapper}>
                <div placeholder className={styles.eduSegmentWrapper}>
                  <div className={styles.eduSegment1}>
                    <Header className={styles.eduSegmentHeader}>
                      Platform
                    </Header>
                    <ol>
                      {platFormContent.map((ele) => (
                        <div
                          key={ele.title}
                          className={
                            activePlatformItem === ele.id
                              ? styles.eduActiveTitle1
                              : styles.eduTitle
                          }
                          onClickCapture={() => setPlatformActiveItem(ele.id)}
                        >
                          <li>{ele.title}</li>
                        </div>
                      ))}
                    </ol>
                  </div>
                  <Segment
                    className={`${styles.eduSegment2} ${styles.segment2Div}`}
                  >
                    <p className={styles.eduActiveHeader}>
                      {currentPlatformObj.title}
                    </p>
                    <div>
                      {currentPlatformObj.content.map((ele) => (
                        <div key={ele.title}>{HTMLReactParser(ele)}</div>
                      ))}
                    </div>
                  </Segment>
                </div>
              </div>
            </div>
          </GridColumn>
        </Grid>
      </div>
    );
  };

  const renderIntegrateDesktopView = () => (
    <div className={styles.integrateSchoolWrapper}>
      <div className={styles.sclDiv}>
        <LazyLoadImage src={banner3} />
      </div>
      <div className={styles.sclContentDiv}>
        <h2 className={styles.sclContentHeader}>
          Why should school integrate schoolage?
        </h2>
        <p>
          Integrating SchoolAge into traditional schooling systems is a
          visionary step that promises to redefine educational landscapes. This
          innovative platform melds the best of AI technology with the richness
          of diverse educational philosophies, creating a personalized learning
          experience that caters to each student&apos;s unique needs and styles.
          SchoolAge encapsulates a future-focused curriculum that cultivates
          essential 21st-century skills such as critical thinking, digital
          fluency, and creative problem-solving. It extends beyond academic
          excellence, nurturing personal, social, and emotional growth to equip
          students with the empathy, ethics, and resilience essential for
          holistic success.
        </p>
        <p>
          The inclusion of SchoolAge&apos;s innovative Virtual Ateliers
          transforms traditional learning spaces into dynamic environments where
          students can experiment, innovate, and collaborate on projects that
          push the boundaries of their creativity. Designed by experts in EdTech
          and Learning Sciences, the curriculum is not only scientifically sound
          but continuously evolving to stay at the forefront of educational
          advancements. SchoolAge also emphasizes global competence and
          sustainability, preparing students to be responsible global citizens
          in an eco-conscious world.
        </p>
        <p>
          This platform is scalable and adaptable, designed to enhance existing
          educational frameworks seamlessly, ensuring that schools can adopt it
          without disruption. SchoolAge is more than just a tool; it is a
          long-term educational partner, evolving with the educational community
          to meet emerging challenges and opportunities. Adopting SchoolAge
          means investing in a future where education transcends traditional
          learning, equipping students to thrive in an ever-changing global
          landscape.
        </p>
      </div>
    </div>
  );

  const renderIntegrateMobileView = () => (
    <div className={styles.sclContentMblWrapper}>
      <h2 className={styles.sclContentHeader}>
        Why should school integrate schoolage?
      </h2>
      <Image src={banner3} className={styles.sclImg} size="medium" />
      <p>
        Integrating SchoolAge into traditional schooling systems is a visionary
        step that promises to redefine educational landscapes. This innovative
        platform melds the best of AI technology with the richness of diverse
        educational philosophies, creating a personalized learning experience
        that caters to each student&apos;s unique needs and styles. SchoolAge
        encapsulates a future-focused curriculum that cultivates essential
        21st-century skills such as critical thinking, digital fluency, and
        creative problem-solving. It extends beyond academic excellence,
        nurturing personal, social, and emotional growth to equip students with
        the empathy, ethics, and resilience essential for holistic success.
      </p>
      <p>
        The inclusion of SchoolAge&apos;s innovative Virtual Ateliers transforms
        traditional learning spaces into dynamic environments where students can
        experiment, innovate, and collaborate on projects that push the
        boundaries of their creativity. Designed by experts in EdTech and
        Learning Sciences, the curriculum is not only scientifically sound but
        continuously evolving to stay at the forefront of educational
        advancements. SchoolAge also emphasizes global competence and
        sustainability, preparing students to be responsible global citizens in
        an eco-conscious world.
      </p>
      <p>
        This platform is scalable and adaptable, designed to enhance existing
        educational frameworks seamlessly, ensuring that schools can adopt it
        without disruption. SchoolAge is more than just a tool; it is a
        long-term educational partner, evolving with the educational community
        to meet emerging challenges and opportunities. Adopting SchoolAge means
        investing in a future where education transcends traditional learning,
        equipping students to thrive in an ever-changing global landscape.
      </p>
    </div>
  );

  const renderArrowSvg = () => {
    return (
      <>
        <div className={styles.arrowWrapper}>
          <svg
            width="1152"
            height="36"
            viewBox="0 0 1152 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 19.7031L1132 19.703"
              stroke="#FFC051"
              strokeWidth="2"
              strokeLinecap="round"
              strokeDasharray="8.08 8.08"
            />
            <path
              d="M1 15.2031L11 25.2031M11 15.2031L1 25.2031"
              stroke="#00B1E5"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1149.8 19.7477L1118.56 5.24164L1118.55 5.24341L1125.74 19.8407L1149.8 19.7477Z"
              fill="#00B1E5"
            />
            <path
              d="M1118.61 35.695L1149.8 19.7488L1125.74 19.8418L1118.61 35.695Z"
              fill="#59D9FF"
            />
          </svg>
        </div>
      </>
    );
  };

  return (
    <div className={styles.homeScreenWrapper}>
      <EarleyYears>
        <div className={styles.btn}>
          <div
            className={styles.enquirebtn}
            onClickCapture={() => setRegModalOpen(true)}
          >
            Enquire
            <Icon name="arrow right" className={styles.arrowIcon} />
          </div>
          <div
            className={styles.videobtn}
            onClickCapture={() => setVideoModalOpen(true)}
          >
            Video
            <Icon name="play circle outline" className={styles.playIcon} />
          </div>
        </div>
      </EarleyYears>
      <div className={styles.mainContentContainer}>
        {/* <div>
          <LazyLoadImage
            src={homeFlowerImage}
            className={styles.homeFlowerImage}
          />
        </div> */}
        {/* {windowWidth > mediaBreakpoint.mobile ? (
          <img src={homeDotedImage} className={styles.dotedImage} />
        ) : (
          <img src={mobileDotedImage} className={styles.dotedImage} />
        )} */}
        <div className={styles.mainContent}>
          <div className={styles.specialContent}>
            <h4 className={styles.contentHeader}>
              What makes schoolage Special??
            </h4>
            <Grid centered>
              {ioacSpecialContent.map((e) => {
                return (
                  <>
                    <Grid.Column
                      computer={5}
                      mobile={16}
                      className={styles.splContentWrapper}
                    >
                      <Image src={e.image} />
                      <Header textAlign="center">{e.title}</Header>
                      {e.content.map((ele) => (
                        <>
                          <div>{ele}</div>
                          <br />
                        </>
                      ))}
                    </Grid.Column>
                  </>
                );
              })}
            </Grid>
          </div>
        </div>
      </div>

      <Container className={styles.programContainer}>
        <h1 className={styles.programHead}> OUR PROGRAMS</h1>
        <Programs />
      </Container>
      {windowWidth > mediaBreakpoint.mobile ? (
        <>
          {renderIntegrateDesktopView()}
          {renderArrowSvg()}
        </>
      ) : (
        <>{renderIntegrateMobileView()}</>
      )}

      <div className={styles.features}>
        <h1 className={styles.featuresHeader}>Features</h1>
        {windowWidth > mediaBreakpoint.mobile ? (
          <>{renderFeatureDesktopView()}</>
        ) : (
          <>{renderFeatureMobileView()}</>
        )}
      </div>

      {/* <PartnerSlider /> */}
      {/* <div className={styles.reviewSection}>
        <ReviewSection />
      </div> */}
      {regModalOpen === true && (
        <>
          <Modal open>
            <Modal.Content>
              <RegistrationForm handleRegModal={setRegModalOpen} />
            </Modal.Content>
          </Modal>
        </>
      )}
      {videoModalOpen === true && (
        <>
          <Modal onClose={() => setVideoModalOpen(false)} open size="small">
            <Modal.Content>
              <ModalDescription>
                <div className={styles.comingSoon}>
                  <img src={comingSoon} />
                </div>
              </ModalDescription>
            </Modal.Content>
            <ModalActions>
              <Button color="orange" onClick={() => setVideoModalOpen(false)}>
                Cancel
              </Button>
            </ModalActions>
          </Modal>
        </>
      )}
    </div>
  );
};

export default HomeScreen;
