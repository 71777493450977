/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useState } from "react";
import styles from "./scss/Programs.module.scss";
import { mediaBreakpoint } from "globals/utils/globalVariables";
import { Header, Image } from "semantic-ui-react";
import { programContent } from "./ProgramsData";
import ProgramsMobile from "./ProgramsMobile";
// import { useHistory } from "react-router-dom";
const Programs = () => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setwindowWidth(window.innerWidth);
  }, []);

  return (
    <>
      {windowWidth > mediaBreakpoint.mobile ? (
        <>
          {programContent.map((ele) => (
            <React.Fragment key={ele.id}>
              <div className={styles.programWrapper}>
                <Header textAlign="center">{ele.name}</Header>
                <div>{ele.content}</div>
                {ele.imgPosition === "right" ? (
                  <>
                    <Image className={styles.rightImg} src={ele.image} />
                  </>
                ) : (
                  <>
                    <Image className={styles.leftImg} src={ele.image} />
                  </>
                )}
              </div>
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          <ProgramsMobile />
        </>
      )}
    </>
  );
};
export default Programs;
